import cn from "classnames"
import React from "react"
import Button from "../../freestanding/button/button"
import Container from "../../freestanding/containers/container"
import Grid from "../../freestanding/containers/grid"
import ContentVisual from "../../freestanding/content/content-visual"
import { Analytics } from "../../freestanding/shared-types"
import { pb32 } from "../../freestanding/utils/padding.module.css"
import * as styles from "./hero-image.module.css"

export interface PropTypes {
  id: string
  title: string | React.ReactElement
  description: string | React.ReactElement
  image: React.ReactElement
  buttons?: Array<HeroCTA>
  logoUrl?: string
}

export interface HeroCTA {
  ctaTitle: string
  style: "filled" | "outlined" | "text"
  to: string
  openInNewWindow?: boolean
  iconRight?: React.ReactElement
  className?: string
  analytics?: Analytics
}

const HeroImage = ({
  title,
  description,
  buttons,
  image,
  id,
  logoUrl,
}: PropTypes) => (
  <div id={id} className={cn(styles.heroImage)}>
    <Container fluid={true} alignItems={"center"}>
      <Grid
        lg={id.indexOf("casestudy") != -1 ? 6 : 5}
        md={6}
        sm={12}
        xs={12}
        className={cn(pb32)}
      >
        {logoUrl ? (
          <Container className={cn(pb32)} justify={"center-xs"}>
            <img
              width={"30%"}
              height={"100%"}
              loading="lazy"
              src={logoUrl}
              alt=""
            ></img>
          </Container>
        ) : null}
        <Container className={cn(pb32, styles.title)}>
          <h1 className={cn("font-h1", pb32)}>{title}</h1>
          <p className={cn("font-p-large")}>{description}</p>
        </Container>
        <Container justify={"start"}>
          {buttons &&
            buttons.map(
              (
                {
                  ctaTitle,
                  style,
                  to,
                  openInNewWindow,
                  iconRight,
                  className,
                  analytics,
                },
                index,
              ) => (
                <Button
                  className={cn("button-group", className)}
                  style={style}
                  key={index}
                  iconRight={iconRight}
                  openInNewWindow={openInNewWindow}
                  to={to}
                  analytics={analytics}
                >
                  {ctaTitle}
                </Button>
              ),
            )}
        </Container>
      </Grid>
      <Grid lg={id.indexOf("casestudy") != -1 ? 4 : 6} md={6} sm={12} xs={12}>
        <ContentVisual>{image}</ContentVisual>
      </Grid>
    </Container>
  </div>
)

export default HeroImage
